// Replace the below API_URL value with valid API endpoint domain for running the application locally
const API_URL = `https://gsm-edge-uat-api.gsm-tech-prod.nikecloud.com/api` //process.env.API_URL_DEV;

export const serverHost = `${API_URL}/`;

export const apiConfig = {
  getUserDetails: {
    name: 'getUserDetails',
    endPointUrl: `${serverHost}getProfile/v1`,
    method: 'GET'
  },
  materialSearch: {
    name: 'materialSearch',
    endPointUrl: `${serverHost}materialFetch/v1`,
    method: 'POST'
  },
  colorSearch: {
    name: 'colorSearch',
    endPointUrl: `${serverHost}colorFetch/v1`,
    method: 'POST'
  },
  vendorSearch: {
    name: 'vendorSearch',
    endPointUrl: `${serverHost}vendorSearch/v1`,
    method: 'POST'
  },
  vendorFetch: {
    name: 'vendorFetch',
    endPointUrl: `${serverHost}vendorFetch/v1`,
    method: 'POST'
  },
  vendorGridData: {
    name: 'vendorGridData',
    endPointUrl: `${serverHost}vendorDataDashboard/v1`,
    method: 'GET'
  },
  vendorUpdate: {
    name: ' vendorUpdate',
    endPointUrl: `${serverHost}vendorUpdate/v1`,
    method: 'PUT'
  },
  colorGridData: {
    name: 'colorGridData',
    endPointUrl: `${serverHost}colorDataDashboard/v1`,
    method: 'GET'
  },
  addUser: {
    name: 'addUser',
    endPointUrl: `${serverHost}addUser/v1`,
    method: 'POST'
  },
  dashboardCount: {
    name: 'dashboardCount',
    endPointUrl: `${serverHost}dashboardCount/v1`,
    method: 'GET'
  },
  userData: {
    name: 'userData',
    endPointUrl: `${serverHost}listUser/v1`,
    method: 'GET'
  },
  createProfile: {
    name: 'userData',
    endPointUrl: `${serverHost}createProfile/v1`,
    method: 'POST'
  },
  getGroupList: {
    name: 'getGroupList',
    endPointUrl: `${serverHost}groupList/v1`,
    method: 'GET'
  },
  updateUser: {
    name: 'updateUser',
    endPointUrl: `${serverHost}updateUser/v1`,
    method: 'PUT'
  },
  colorUpdate: {
    name: ' colorUpdate',
    endPointUrl: `${serverHost}colorUpdate/v1`,
    method: 'PUT'
  },
  materialData: {
    name: 'materialList',
    endPointUrl: `${serverHost}materialList/v1`,
    method: 'GET'
  },
  locationSearch: {
    name: 'locationSearch',
    endPointUrl: `${serverHost}locationSearch/v1`,
    method: 'GET'
  },
  updateMaterial: {
    name: 'updateMaterial',
    endPointUrl: `${serverHost}materialUpdate/v1`,
    method: 'GET'
  },
  materialVendorSearch: {
    name: 'materialVendorSearch',
    endPointUrl: `${serverHost}vendorManager/v1`,
    method: 'GET'
  },
  materialSearchByLoc: {
    name: 'materialSearchByLoc',
    endPointUrl: `${serverHost}matSearchByLocId/v1`,
    method: 'GET'
  },
  vendorData: {
    name: 'vendorData',
    endPointUrl: `${serverHost}getMDOVendor/v1`,
    method: 'GET'
  },
  updateVendorDetails: {
    name: 'updateVendorDetails',
    endPointUrl: `${serverHost}updateMDOVendor/v1`,
    method: 'PUT'
  },
  columnUpdate: {
    name: 'columnUpdate',
    endPointUrl: `${serverHost}columnUpdate/v1`,
    method: 'PUT'
  },
  editVendorMapping: {
    name: 'editVendorMapping',
    endPointUrl: `${serverHost}getVendorMap/v1`,
    method: 'GET'
  },
  updateVendorMapping: {
    name: 'updateVendorMapping',
    endPointUrl: `${serverHost}updateVendorMap/v1`,
    method: 'PUT'
  },
  editMaterialMapping: {
    name: 'editMaterialMapping',
    endPointUrlAll: `${serverHost}getMaterialMapOthers/v1`,
    endPointUrl: `${serverHost}getAirmiMaterialMap/v1`,
    method: 'GET'
  },
  updateMaterialMapping: {
    name: 'updateMaterialMapping',
    endPointUrlAll: `${serverHost}updateMaterialMapOthers/v1`,
    endPointUrl: `${serverHost}updateAirmiMaterialMap/v1`,
    method: 'PUT'
  },
  getColorMapping: {
    name: 'getColorMapping',
    endPointUrl: `${serverHost}getMDOColorMapData/v1`,
    method: 'GET'
  },
  updateColorMapping: {
    name: 'updateColorMapping',
    endPointUrl: `${serverHost}updateMDOColorMap/v1`,
    method: 'PUT'
  },
  addVendorGroupName: {
    name: 'addVendorGroup',
    endPointUrl: `${serverHost}addVendorGroup/v1`,
    method: 'POST'
  },
  editVendorGroupName: {
    name: 'updateVendorGroup',
    endPointUrl: `${serverHost}updateVendorGroup/v1`,
    method: 'PUT'
  },
  submitUpload: {
    name: 'simpleAdapterUpload',
    endPointUrl: `${serverHost}simpleAdapterUpload/v1`,
    method: 'POST'
  },
  getuploadedFiles: {
    name: 'simpleAdapterFetch',
    endPointUrl: `${serverHost}simpleAdapterFetch/v1`,
    method: 'GET'
  },
  getFileDetails: {
    name: 'getFileDetails',
    endPointUrl: `${serverHost}simpleAdapterFetchFroms3/v1`,
    method: 'GET'
  },
  poShipmentMapping: {
    name: 'poShipmentMapping',
    endPointUrl: `${serverHost}addPoMapping/v1`,
    method: 'POST'
  },
  getPOHeader: {
    name: 'getPOHeader',
    endPointUrl: `${serverHost}getPOShipmentRecords/v1`,
    method: 'GET'
  },
  getPOItem: {
    name: 'getPOItem',
    endPointUrl: `${serverHost}getPOShipmentDetails/v1`,
    method: 'GET'
  },
  getPOMapping: {
    name: 'getPOMapping',
    endPointUrl: `${serverHost}getPoMapping/v1`,
    method: 'GET'
  },
  sendPOEmail: {
    name: 'sendPOEmail',
    endPointUrl: `${serverHost}sendMail/v1`,
    method: 'GET'
  },
  poVendorSearch: {
    name: 'poVendorSearch',
    endPointUrl: `${serverHost}poVendorSearch/v1`,
    method: 'GET'
  },
  getPOUserDetails: {
    name: 'getPoUserDetails',
    endPointUrl: `${serverHost}getPoUserDetails/v1`,
    method: 'GET'
  },
  submitUser: {
    name: 'submitUser',
    endPointUrl: `${serverHost}bulkUsersUpload/v1`,
    method: 'POST'
  },
  getUserFiles: {
    name: 'getUserFiles',
    endPointUrl: `${serverHost}getBulkUsersData/v1`,
    method: 'GET'
  },
  getUserFileDetails: {
    name: 'getUserFileDetails',
    endPointUrl: `${serverHost}getBulkUsersS3Data/v1`,
    method: 'GET'
  },
  getAllAccessForIdlockerUser: {
    name: 'getAllAccessForIdlockerUser',
    endPointUrl: `${serverHost}idlocker/getAllAccessForIdlockerUser`,
    method: 'GET'
  },
  getIdLockerUsers: {
    name: 'getIdLockerUsers',
    endPointUrl: `${serverHost}idLockerGetUsers/v1`,
    method: 'POST'
  },
  getIdLockerAccesses: {
    name: 'idLockerGetAccess',
    endPointUrl: `${serverHost}idLockerGetAccess/v1`,
    method: 'GET'
  },
  idLockerGetUsersAccess: {
    name: 'idLockerGetUsersAccess',
    endPointUrl: `${serverHost}idLockerGetUsersAccess/v1`,
    method: 'GET'
  },
  submitIdLockerAccess: {
    name: 'idLockerAddRemoveAccess',
    endPointUrl: `${serverHost}idLockerAddRemoveAccess/v1`,
    method: 'POST'
  },
  getIdLockerRequestList: {
    name: 'getIdLockerRequestList',
    endPointUrl: `${serverHost}idLockerGetStatus/v1`,
    method: 'GET'
  }
}
